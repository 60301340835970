import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../../config';

import StarRating from '../starRating';
import { useTranslation } from "react-i18next";

const ReviewsComponent = ({ courseId }) => {

    const { t } = useTranslation();

    const { userData, isAuth } = useSelector((state) => state.auth);
    const [rating, setRating] = useState(0);
    const [hoveredRating, setHoveredRating] = useState(0);
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState(false);
    const [showRating, setShowRating] = useState(true);

    useEffect(() => {
        getReviews();
    }, [])

    const handleStarHover = (hovered) => {
        setHoveredRating(hovered);
    };

    const handleStarClick = (selected) => {
        setRating(selected);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleSubmitReview = async () => {
        try {
            setLoading(true)
            const payload = {
                courseId: courseId,
                studentId: userData.id,
                stars: rating,
                comment: comment
            }
            console.log(payload)
            const { data } = await axios.post(BASE_URL + '/reviews/', payload);
            console.log(data)
            getReviews();
        } catch (e) {
            console.log(e.response.data)
            setLoading(false)
        } finally {
            setLoading(false)
        }

    }

    const getReviews = async () => {
        try {
            const result = await axios.get(BASE_URL + '/reviews/');
            console.log(result.data)
            const isReviewed = result.data.map(x => x.studentId).includes(userData.id)
            if(isReviewed)
            {
                setShowRating(false);
            }
            setReviews(result.data)

        } catch (e) {
            console.log(e)
        } finally {

        }

    }

    return (
        <div className='col-lg-12 m-4'>
            {showRating &&
            <div className='course-review-btn'>
            <div className="review-comment">
                <div className="comment-rating">
                    <p>¿{t('HowDoYouRateTheCourse')}?</p>
                    <ul>
                        {[1, 2, 3, 4, 5].map((star) => (
                            <li
                                key={star}
                            >
                                <a href="#">
                                    <i
                                        className={`${star <= (hoveredRating || rating) ? 'fas' : 'fal'} fa-star`}
                                        onMouseEnter={() => handleStarHover(star)}
                                        onMouseLeave={() => handleStarHover(0)}
                                        onClick={() => handleStarClick(star)}
                                    ></i>
                                </a>
                            </li>
                        ))}
                    </ul>

                </div>
                <div className="comment mt-20">
                    <textarea
                        placeholder={t('WriteYourReview')}
                        value={comment}
                        onChange={handleCommentChange}
                        className='form-control'
                    ></textarea>
                </div>
                <button className="edu-btn mt-20" onClick={() => handleSubmitReview()} disabled={loading}>{loading ? t('Loading') : t('SendReview')}</button>
            </div>
        </div>
            }

            <div className="course-detalis-reviews pt-15">
                {reviews && reviews.length > 0 &&
                    <div className="course-detalis-reviews-tittle">
                        <h3>{t('Reviews')} ({reviews.length})</h3>
                    </div>
                }

                {reviews && reviews.length > 0 && reviews.map((data, index) => {
                    return (
                        <div key={data.id} className="course-review-item mb-30">
                            <div className="course-reviews-img">
                                <a href="#"><img src={data?.student?.photo} style={{ width: '50px', borderRadius: '50px' }} alt="img not found" /></a>
                            </div>
                            <div className="course-review-list">
                                <h5><a href="#">{data?.student?.firstName} {data?.student?.lastName}</a></h5>
                                <StarRating rating={data.stars} />
                                <p>{data.comment}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>


    )
}

export default ReviewsComponent;