import * as React from "react"
import CourseVideo from "../../components/courseVideo"
import {graphql} from 'gatsby';

const CourseVideoPage = (props) => (
    <CourseVideo id={props.params.id} location={props.location} />
)

export default CourseVideoPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
