import React, { useRef, useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import './courseVideo.scss';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { BASE_URL, CDN_URL } from '../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgress, setProgressAction } from '../../redux/progress/progressSlice';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ReviewsComponent from '../reviews';
import { FaFileImage ,FaFile ,FaFileCode,FaFileArchive,FaFilePdf,FaExternalLinkAlt   } from "react-icons/fa";
import axiosWithAuth from '../../utils/axios';
import { useTranslation } from "react-i18next";
const CourseVideo = ({ id, location }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { userData, isAuth } = useSelector((state) => state.auth);
    const [courseDetails, setCourseDetails] = useState(null);
    const [lessons, setLessons] = useState(null);
    const [videoSelected, setVideoSelected] = useState(null);
    const [videoProgress, setVideoProgress] = useState(null);
    const [progressData, setProgressData] = useState(null);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const videoRef = useRef();
    
    useEffect(() => {
        // Función para actualizar el estado con la altura y anchura de la ventana
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        
        // Agregar el event listener
        window.addEventListener('resize', handleResize);
        
        // Llamar a handleResize inmediatamente para actualizar el estado inicial
        handleResize();

        // Eliminar el event listener en el cleanup del componente
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isAuth) {
            getLessons();
        } else {
            navigate('/');
        }
    }, [location]);


    const getProgress = async (lessonId, videoUrl)=> {
        try {
            const queryParams = {studentId: userData.id,courseId: id};
            
            const { data: progressData } = await axiosWithAuth().get(BASE_URL + '/student-progress', {params: queryParams});
            if(lessonId && videoUrl){
                return progressData.find(x=> x.lessonId == lessonId && x.videoUrl == videoUrl)
            }
            return progressData;
        } catch (error) {
            
        }
    }

    const getLessons = async () => {
        try {
            const studentResult = await axiosWithAuth().get(BASE_URL + '/students/me');

            const isEnrolled = studentResult.data.enrolledCourses.some((data) => data.course.id === id);

            if (isEnrolled) {

                //Course Data
                const {data: resultCourse} = await axiosWithAuth().get(BASE_URL + '/courses/' + id);
                setCourseDetails(resultCourse);

                const {data: lessonsData} = await axiosWithAuth().get(BASE_URL + '/lessons?courseId=' + id);
                lessonsData.sort((a, b) => a.order - b.order);

                setLessons(lessonsData);

                 //Progress Data
                const progressResult = await getProgress();
                if(progressResult && progressResult.length > 0){
                    setProgressData(progressResult);

                    const progressDataByUpdatedAt = [...progressResult];
                    progressDataByUpdatedAt.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                    
                    //return;
                    const lastLessonId = progressDataByUpdatedAt[0].lessonId;
                    const lastVideoId = progressDataByUpdatedAt[0].videoUrl
                    handleSelectVideo(lastLessonId,lastVideoId)
                }else{
                    
                    const firstLessonId = lessonsData[0].id;
                    const firstVideoId = lessonsData[0].videos[0].url
                    handleSelectVideo(firstLessonId,firstVideoId)
                }
            } else {
                navigate('/course-details/' + id);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectVideo = async (lessonId, videoUrl) => {

        try {
            dispatch(saveProgress());
            const apiProgressData = await getProgress(lessonId,videoUrl);
            
            if (apiProgressData) {
                // Actualizar el progreso local desde el progreso de la base de datos
                dispatch(setProgressAction({
                    studentId: userData.id,
                    courseId: id,
                    lessonId: lessonId,
                    videoUrl: videoUrl,
                    progressSeconds: apiProgressData.progressSeconds,
                    percentageCompleted: apiProgressData.percentageCompleted,
                }));
                setVideoProgress(apiProgressData.progressSeconds)

            }
            if (!apiProgressData) {
                // Actualizar el progreso local desde el progreso de la base de datos
                dispatch(setProgressAction({
                    studentId: userData.id,
                    courseId: id,
                    lessonId: lessonId,
                    videoUrl: videoUrl,
                    progressSeconds: null,
                    percentageCompleted: null,
                }));
                setVideoProgress(null)
            }
            setVideoSelected({ lessonId, videoUrl, progress: apiProgressData?.percentageCompleted });
        } catch (error) {
            console.error('Error al seleccionar el video', error);
        }
    };

    const handleProgress = (e) => {
        const { playedSeconds, played } = e;
        const percentageCompleted = played * 100;
        const timeElapsed = playedSeconds;

        dispatch(setProgressAction({
            courseId: id,
            studentId: userData.id,
            lessonId: videoSelected.lessonId,
            videoUrl: videoSelected.videoUrl,
            progressSeconds: timeElapsed,
            percentageCompleted: percentageCompleted,
        }));
    };

    const handleReady = () => {
        if (videoRef.current) {
            videoRef.current.seekTo(videoProgress);
        }
    };


    useEffect(() => {
        const onBeforeUnload = (ev) => {
            dispatch(saveProgress());
            ev.returnValue = "¿Seguro que desea salir?";
            return "¿Seguro que desea salir?";
        };

        window.addEventListener("beforeunload", onBeforeUnload);

        return () => {
            dispatch(saveProgress());
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, []);

    const handlePause = async (e) => {
        dispatch(saveProgress())
    }

    const handleEnd = (e) => {
        dispatch(saveProgress())
    }

    return (
        <>{courseDetails &&
            <>
                <header className="navbar navbar-light sticky-top bg-light flex-md-nowrap p-0 shadow">
                    <Link className="navbar-brand col-md-3 col-lg-2 me-0 px-3" to="/">
                        <img src="/assets/img/logo/logo.png" style={{ width: '180px' }} alt="logo" />
                    </Link>
                    
                    
                    {/* <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" style={{ right: 0, padding: '10px', margin: '10px' }} data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button> */}
                </header>

                <div className="container-fluid course-video-section">
                    <div className="row">
                        <nav id="sidebarMenu" className=" col-lg-3 d-none d-md-block bg-light sidebar collapse p-0">
                            <p className='p-2 mt-3' style={{ fontSize: '1.5rem', fontWeight: '700' }}>{courseDetails.name}</p>
                            <div className="course-curriculam-accodion mt-30">
                                <div className="accordion" id="accordionExample">
                                    {lessons && lessons.map((data, index) => {
                                        const isAcordeonAbierto = data.id === videoSelected?.lessonId;
                                        return (
                                            <div className="accordion-item" key={index}>
                                                <h2 className="accordion-header">
                                                    <button 
                                                    className={`accordion-button d-flex ${isAcordeonAbierto ? 'collapsed' : ''}`}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse-${index}`}
                                                    aria-expanded={isAcordeonAbierto}
                                                    aria-controls={`collapse-${index}`}>
                                                        <div className='flex'>
                                                            <div style={{ fontSize: '.875rem' }}><strong>{t('Lesson')} {data.order+1}: </strong> {data.name}</div>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div
                                                id={`collapse-${index}`}
                                                className={`accordion-collapse collapse ${isAcordeonAbierto ? 'show' : ''}`}
                                                data-bs-parent="#accordionExample">
                                                    <div className="accordion-body p-0">
                                                        {data.videos.map((items, indexItems) => {
                                                            const isVideoSelected = items.url === videoSelected?.videoUrl;
                                                            const isVideoCompleted = progressData && progressData.find(x=> x.videoUrl == items?.url)?.percentageCompleted
                                                            
                                                            return (
                                                                <div className={`course-curriculum-content d-sm-flex justify-content-between align-items-center ${isVideoSelected ? 'curriculum-active' : ''}`} key={indexItems}>
                                                                    <div className="course-curriculum-info">
                                                                        {Math.round(isVideoCompleted) < 99 && <i className="flaticon-youtube"></i>}
                                                                        {Math.round(isVideoCompleted) > 99 && <i className="fa fa-check"></i>}
                                                                        
                                                                        <button type="button" onClick={() => handleSelectVideo(data.id, items.url)}>
                                                                            <h4>{items.name}</h4>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </nav>
                        <main className="col-lg-9 p-0">
                            {/* Video Here */}
                            <div className="video-container">
                                {videoSelected &&
                                <>
                                    <ReactPlayer
                                        className='apprende-player'
                                        url={`${CDN_URL}/hls/output/${videoSelected.videoUrl}/${videoSelected.videoUrl}_1080p.m3u8`}
                                        ref={videoRef}
                                        controls={true}
                                        width="100%"
                                        height="auto"
                                        playing
                                        config={{ hls: { withCredentials: false } }}
                                        onProgress={handleProgress}
                                        onReady={handleReady}
                                        onPause={handlePause}
                                        onEnded={handleEnd}
                                    />
                                </>
                                }
                            </div>
                            <div className='d-block'>
                            <Tabs
                            defaultActiveKey="comments"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            >
                                <Tab eventKey="comments" title={t('Reviews')}>
                                    <ReviewsComponent courseId={id}/>
                                </Tab>
                                {courseDetails.courseFiles.length > 0 &&
                                <Tab eventKey="courseFiles" title={t('Resources')}>
                                    <div className='card m-4'>
                                        <div className='card-body'>
                                            <div className='card-title'>
                                            {t('Resources')}
                                            </div>
                                            <ul>
                                    {courseDetails.courseFiles.map((data)=>{
                                        return(
                                            <li key={data} className='d-flex mx-2 align-items-center'>
                                                {data.type == 'image' && <FaFileImage  />}
                                                {data.type == 'pdf' && <FaFilePdf  />}
                                                {data.type == 'zip' && <FaFileArchive  />}
                                                {data.type == 'code' && <FaFileCode />}
                                                {data.type == 'text' && <FaFile />}
                                                {data.type == 'url' && <FaExternalLinkAlt />}
                                                <a className='mx-2' target="_blank" href={data.url}> {t('View')} - {data.name}</a>
                                            </li>
                                        )
                                    })}
                                    </ul>
                                        </div>
                                    </div>
                                    
                                </Tab>
                                }
                                {windowSize.width <= 768 &&
                                <Tab eventKey="content-course" className='d-block d-md-none' title={t('CourseContent')}>
                                <div className="course-curriculam-accodion mt-30">
                                    <div className="accordion" id="accordionExample">
                                        {lessons && lessons.map((data, index) => {
                                            const isAcordeonAbierto = data.id === videoSelected?.lessonId;
                                            return (
                                                <div className="accordion-item" key={index}>
                                                    <h2 className="accordion-header">
                                                        <button 
                                                        className={`accordion-button d-flex ${isAcordeonAbierto ? 'collapsed' : ''}`}
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#collapse-${index}`}
                                                        aria-expanded={isAcordeonAbierto}
                                                        aria-controls={`collapse-${index}`}>
                                                            <div className='flex'>
                                                                <div style={{ fontSize: '.875rem' }}>{data.name}</div>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div
                                                    id={`collapse-${index}`}
                                                    className={`accordion-collapse collapse ${isAcordeonAbierto ? 'show' : ''}`}
                                                    data-bs-parent="#accordionExample">
                                                        <div className="accordion-body p-0">
                                                            {data.videos.map((items, indexItems) => {
                                                                const isVideoSelected = items.url === videoSelected?.videoUrl;
                                                                const isVideoCompleted = progressData && progressData.find(x=> x.videoUrl == items?.url)?.percentageCompleted
                                                                return (
                                                                    <div className={`course-curriculum-content d-sm-flex justify-content-between align-items-center ${isVideoSelected ? 'curriculum-active' : ''}`} key={indexItems}>
                                                                        <div className="course-curriculum-info">
                                                                            {isVideoCompleted < 99 && <i className="flaticon-youtube"></i>}
                                                                            {isVideoCompleted > 99 && <i className="fa fa-check"></i>}
                                                                            
                                                                            <button type="button" onClick={() => handleSelectVideo(data.id, items.url)}>
                                                                                <h4>{items.name}</h4>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                </Tab> 
                                
                                }
                                
                            </Tabs>
                            </div>
                            
                            
                        </main>
                    </div>
                </div>
            </>
        }

        </>
    );
};

export default CourseVideo;